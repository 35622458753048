<script setup lang="ts">
import { ref } from "vue"
import { useHead } from "@unhead/vue"

const years = ref([2024, 2025])
const monthlyYear = ref(new Date().getFullYear())
const quarterlyYear = ref(new Date().getFullYear())

const months = ref([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
])

const quarters = ref([
  "Q1",
  "Q2",
  "Q3",
  "Q4",
])

const quarterly2024 = ref([
  {
    quarter: "Q4",
    link: "/transparency/2024/quarterly/4",
  }
])

const quarterly2025 = ref([])

const monthly2024 = ref([
  {
    month: "November",
    link: "/transparency/2024/monthly/11",
  },
  {
    month: "December",
    link: "/transparency/2024/monthly/12",
  },
])

const monthly2025 = ref([
  {
    month: "January",
    link: "/transparency/2025/monthly/1",
  }
])

const getMonthLink = (year: number, month: string) => {
  const monthArray = year === 2024 ? monthly2024.value : monthly2025.value
  return monthArray.find(m => m.month === month)?.link
}

const getQuarterLink = (year: number, quarter: string) => {
  const quarterArray = year === 2024 ? quarterly2024.value : quarterly2025.value
  return quarterArray.find(q => q.quarter === quarter)?.link
}

useHead({
  title: "Transparency - Submit",
  meta: [
    { name: "description", content: "Discover how Submit prioritizes transparency, accountability, and trust. Explore our commitment to privacy, safety, and ethical practices, fostering an inclusive and respectful community." },
    { name: "robots", content: "index,follow" },
  ]
})
</script>

<template>
  <main class="px-2">
    <article class="my-24 max-w-[672px] lg:max-w-[656px] w-full mx-auto flex flex-col flex-grow space-y-16">
      <header class="text-center space-y-3">
        <h1 class="font-display text-3xl sm:text-5xl font-bold dark:text-gold-500 text-gold-700">Transparency</h1>
      </header>
      <section class="space-y-6">
        <p>At Submit, we believe that openness is the foundation of trust. This portal is your gateway to understanding how we operate, grow, and stay true to our mission. Whether it’s updates on our processes, insights into platform performance, or reports on our progress as a Public Benefit Corporation, we're committed to keeping you informed.</p>
        <p>Transparency is more than a principle&mdash;it's how we ensure accountability to the community we serve. Explore our monthly updates, quarterly analytics, and annual mission reports to see the work happening behind the scenes to make Submit the safest, most inclusive space for BDSM, fetish, and kink communities.</p>
      </section>
      <section class="space-y-6">
        <h2 class="text-2xl">Monthly Updates</h2>
        <ul class="flex flex-wrap gap-8">
          <li v-for="year in years" :key="year">
            <button
              class="decoration-2 underline-offset-4 hover:underline"
              :class="{ 'dark:decoration-gold-500 decoration-gold-700 underline dark:text-white': monthlyYear === year }"
              @click="monthlyYear = year"
            >
              {{ year }}
            </button>
          </li>
        </ul>
        <ul class="grid grid-cols-2 sm:grid-cols-4 gap-4">
          <li v-for="month in months" :key="month">
            <template v-if="getMonthLink(monthlyYear, month)">
              <router-link
                class="hover:dark:text-white hover:underline"
                :to="getMonthLink(monthlyYear, month) as string"
              >
                {{ month }}
              </router-link>
            </template>
            <template v-else>
              <span class="dark:text-gray-500 text-gray-400 cursor-not-allowed">
                {{ month }}
              </span>
            </template>
          </li>
        </ul>
      </section>
      <section class="space-y-6">
        <h2 class="text-2xl">Quarterly Analytics</h2>
        <ul class="flex flex-wrap gap-8">
          <li v-for="year in years" :key="year">
            <button
              class="decoration-2 underline-offset-4 hover:underline"
              :class="{ 'dark:decoration-gold-500 decoration-gold-700 underline dark:text-white': quarterlyYear === year }"
              @click="quarterlyYear = year"
            >
              {{ year }}
            </button>
          </li>
        </ul>
        <ul class="grid grid-cols-4 gap-4">
          <li v-for="quarter in quarters" :key="quarter">
            <template v-if="getQuarterLink(quarterlyYear, quarter)">
              <router-link
                class="hover:dark:text-white hover:underline"
                :to="getQuarterLink(quarterlyYear, quarter) as string"
              >
                {{ quarter }}
              </router-link>
            </template>
            <template v-else>
              <span class="dark:text-gray-500 text-gray-400 cursor-not-allowed">
                {{ quarter }}
              </span>
            </template>
          </li>
        </ul>
        <p>Transparency reporting started in November 2024, the first report will be made available in January 2025.</p>
      </section>
      <section class="space-y-6">
        <h2 class="text-2xl">Annual Mission Reports</h2>
        <p>Submit completes its first year as a Public Benefit Corporation in December 2025. The first annual mission report will be made available in January 2026.</p>
      </section>
    </article>
  </main>
</template>
