<script setup lang="ts">
import { useRoute } from "vue-router"
import { ref, onMounted } from "vue"

const route = useRoute()

const year = route.params.year as keyof typeof quarterlyReports.value
const quarter = route.params.quarter as keyof (typeof quarterlyReports.value)[typeof year]

const quarterlyReports = ref({
  "2024": {
    "4": {
      interests: {
        "top10": {
          kinks: [
            {
              name: "Consensual Nonconsent Play",
              url: "/kinks/consensual-nonconsent-play",
            },
            {
              name: "Aftercare",
              url: "/kinks/aftercare",
            },
            {
              name: "Bondage",
              url: "/kinks/bondage",
            },
            {
              name: "Restraints",
              url: "/kinks/restraints",
            },
            {
              name: "BDSM",
              url: "/kinks/bdsm",
            },
            {
              name: "Rope Bondage/Suspension",
              url: "/kinks/rope-bondage-suspension",
            },
            {
              name: "submission",
              url: "/kinks/submission",
            },
            {
              name: "Cuddles",
              url: "/kinks/cuddles",
            },
            {
              name: "Spanking",
              url: "/kinks/spanking",
            },
            {
              name: "Hair Pulling",
              url: "/kinks/hair-pulling",
            }
          ],
          interests: [
            {
              name: "Video Games",
              url: "/interests/video-games",
            },
            {
              name: "Reading",
              url: "/interests/reading",
            },
            {
              name: "Sci-Fi",
              url: "/interests/sci-fi",
            },
            {
              name: "Music",
              url: "/interests/music",
            },
            {
              name: "Writing",
              url: "/interests/writing",
            },
            {
              name: "Board Games",
              url: "/interests/board-games",
            },
            {
              name: "Cooking",
              url: "/interests/cooking",
            },
            {
              name: "Photography",
              url: "/interests/photography",
            },
            {
              name: "Drawing",
              url: "/interests/drawing",
            },
            {
              name: "Kink Safety",
              url: "/interests/kink-safety",
            }
          ]
        },
        added: {
          kinks: 0,
          interests: 15
        }
      },
      community: {
        roleSelections: {
          top10: [
            "Switch",
            "submissive",
            "Dominant",
            "Brat",
            "Exploring",
            "Daddy",
            "Kinkster",
            "Sadist",
            "Masochist",
            "Evolving"
          ]
        },
        genderIdentities: {
          top10: [
            "Woman",
            "Man",
            "Femme",
            "Non-Binary",
            "Genderfluid",
            "Trans Woman",
            "Genderqueer",
            "Masc",
            "Transgender",
            "Agender"
          ]
        },
        sexualOrientations: {
          top10: [
            "Straight",
            "Pansexual",
            "Bisexual",
            "Demisexual",
            "Queer",
            "Heteroflexible",
            "Sapiosexual",
            "Polysexual",
            "Fluctuating/Evolving",
            "Lesbian"
          ]
        },
        romanticOrientations: {
          top10: [
            "Polyromantic",
            "Heteroromantic",
            "Panromantic",
            "Demiromantic",
            "None",
            "Biromantic",
            "Monoromantic",
            "Homoromantic",
            "Aromantic",
            "Gray-Romantic"
          ]
        },
        pronouns: {
          top10: [
            "He/Him",
            "She/Her",
            "She/They",
            "They/Them",
            "None",
            "Any",
            "He/They",
            "Ask",
            "It/Its",
            "Fae/Faer"
          ]
        },
        activeUsers: {
          10: 10105,
          11: 10322,
          12: 10490
        },
        inactiveAccountReminders: {
          10: 0,
          11: 14105,
          12: 26800
        },
        accountDeletions: {
          10: 8,
          11: 5,
          12: 6
        },
        accountTypes: {
          free: 99.7,
          backer: 0.3
        }
      },
      waitlist: {
        total: 7980,
        approvals: 6446,
        averageWaitTime: 10.5,
        removalReasons: [
          "Known Spam IP/Email",
          "Known Bot IP/Email",
          "Automated Activity Detected",
          "Throwaway Email Service Used",
          "Failure to Verify Email",
          "Inappropriate Username",
          "Ban Evasion"
        ],
        throwawayAttempts: {
          10: 191,
          11: 300,
          12: 352
        }
      },
      content: {
        total: 37363,
        audiences: {
          everyone: 39,
          followers: 30,
          relationships: 11,
          circles: 19,
          groups: 1
        },
        commonContentType: "Status Update"
      },
      reactions: {
        types: {
          like: 5459,
          dislike: 167,
          celebrate: 807,
          love: 13187,
          laugh: 915,
          hot: 1212,
          cry: 389,
          angry: 41
        }
      },
      traffic: {
        pageViews: {
          10: 481255,
          11: 509601,
          12: 599002
        },
        bounceRate: {
          10: 32.2,
          11: 35.1,
          12: 30.8
        }
      },
      nerdyStats: {
        releases: {
          10: 37,
          11: 12,
          12: 13
        },
        diff: {
          10: {
            added: 11850,
            removed: 2546
          },
          11: {
            added: 8966,
            removed: 1798
          },
          12: {
            added: 11629,
            removed: 1788
          }
        },
        services: {
          10: 11,
          11: 13,
          12: 14,
          retired: 0
        },
        errors: {
          10: 14.07,
          11: 4.12,
          12: 2.45
        },
        feed: {
          downtime: {
            10: 0,
            11: 0,
            12: 0
          }
        }
      }
    }
  }
})

const initialLoadComplete = ref(false)
const error = ref(false)
const report = ref<any>(null)

const formatNumber = (num: number) => {
  return new Intl.NumberFormat().format(num)
}

const getTotalReactions = (reactions: { types: Record<string, number> }) => {
  return Object.values(reactions.types).reduce((acc, curr) => acc + curr, 0)
}

function getEmojiForType(type: string) {
  return type === "like" ? "👍" : type === "dislike" ? "👎" : type === "love" ? "❤️" : type === "hot" ? "🥵" : type === "laugh" ? "😂" : type === "cry" ? "😢" : type === "angry" ? "😠" : type === "celebrate" ? "🥳" : ""
}

onMounted(() => {
  if (quarterlyReports.value[year][quarter]) {
    report.value = quarterlyReports.value[year][quarter]
  } else {
    error.value = true
  }
  initialLoadComplete.value = true
})
</script>

<template>
  <main class="px-2">
    <article class="my-24 max-w-[672px] lg:max-w-[656px] w-full mx-auto flex flex-col flex-grow space-y-16">
      <header class="text-center space-y-3">
        <small class="text-gray-500 dark:text-gray-400">{{ year }}</small>
        <h1 class="font-display text-3xl sm:text-5xl font-bold dark:text-gold-500 text-gold-700 capitalize">Q{{ quarter }} Analytics Report</h1>
      </header>
      <div class="text-center" v-if="!initialLoadComplete && !error">
        Loading...
      </div>
      <div class="text-center" v-if="initialLoadComplete && error">
        <p>The requested report does not exist or is not available.</p>
      </div>
      <div class="space-y-16" v-if="initialLoadComplete && !error">
        <section class="space-y-6">
          <p>Welcome to our first-ever Quarterly Analytics Report! Our goal with these reports is to provide insight into the demographics, traffic, and operations of Submit. While our monthly transparency reports focus on specific topics, quarterly reports will take a broader approach, offering a high-level look at the platform’s growth and community development.</p>
          <p>These reports will continue to evolve as we discover new and interesting data to share—and as we listen to your feedback on what you’d like to see. For this first report, we’re covering platform demographics, traffic trends, user insights, waitlist data, and more. Since we’re still exploring this format, some sections may feel overly technical or less relevant. If anything stands out that way to you, let us know in the <router-link class="underline decoration-dotted hover:decoration-solid hover:decoration-gold-700 dark:hover:decoration-gold-500" to="/groups/submit-development">Submit Development</router-link> group—we’d love your feedback!</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl font-bold text-gold-700 dark:text-gold-500">Kinks &amp; Interests</h2>
          <p>We’re always working to ensure Submit offers the most diverse and comprehensive selection of kinks and interests for our community. In this report, we’re highlighting the top 10 most popular kinks and (vanilla) interests, along with the number of new kinks and interests added. As our community grows and evolves, so do the ways people express themselves, and we’re committed to continuously expanding our options to reflect that.</p>
          <figure class="grid sm:grid-cols-2 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-lg font-bold mb-3">Top 10 Kinks</h3>
              <ol class="list-decimal list-inside text-sm">
                <li v-for="kink in report.interests.top10.kinks" :key="kink.name">
                  <router-link class="underline decoration-dotted hover:decoration-solid hover:decoration-gold-700 dark:hover:decoration-gold-500" :to="kink.url">{{ kink.name }}</router-link>
                </li>
              </ol>
            </div>
            <div>
              <h3 class="text-lg font-bold mb-3">Top 10 Interests</h3>
              <ol class="list-decimal list-inside text-sm">
                <li v-for="interest in report.interests.top10.interests" :key="interest.name">
                  <router-link class="underline decoration-dotted hover:decoration-solid hover:decoration-gold-700 dark:hover:decoration-gold-500" :to="interest.url">{{ interest.name }}</router-link>
                </li>
              </ol>
            </div>
          </figure>
          <p>Our users can suggest new kinks and interests, and we’re always looking for ways to expand our offerings. If you have a kink or interest that you’d like to see added to Submit, please let us know in the <router-link class="underline decoration-dotted hover:decoration-solid hover:decoration-gold-700 dark:hover:decoration-gold-500" to="/groups/submit-development">Submit Development</router-link> group.</p>
          <figure class="grid sm:grid-cols-2 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.interests.added.kinks) }}</h3>
              <span>New Kinks</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.interests.added.interests) }}</h3>
              <span>New Interests</span>
            </div>
          </figure>
          <p>0 new kinks 😥, we definitely need to up our kink adding game!</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl font-bold text-gold-700 dark:text-gold-500">Community</h2>
          <p>Understanding our community is essential to ensuring Submit remains a welcoming and inclusive space. In this section, we’re sharing insights into the most common role selections, pronouns, gender identities, romantic orientations, and sexual orientations chosen by our members. This data offers a glimpse into the diversity of our user base and how people choose to represent themselves on the platform.</p>
          <p>Beyond identity, we’re also looking at platform activity, including the average number of active users per month, the number of account deletion requests, and how many inactive account reminders were sent. These metrics help us track engagement and ensure our approach to account management remains transparent and user-focused.</p>
          <figure class="grid gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-lg font-bold mb-3">Top 10 Role Selections</h3>
              <ol class="list-decimal list-inside text-sm">
                <li v-for="role in report.community.roleSelections.top10" :key="role">
                  {{ role }}
                </li>
              </ol>
            </div>
          </figure>
          <p>Role choices on Submit continue to reflect the wide range of dynamics and preferences within our community. "Switch" takes the top spot, showing just how many users embrace versatility in their roles. Classic dynamics like "submissive" and "Dominant" remain strong, while "Exploring" and "Evolving" highlight those still figuring out where they fit. The presence of "Brat," "Sadist," and "Masochist" further showcases the diverse ways people engage with kink and power exchange.</p>
          <figure class="grid gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-lg font-bold mb-3">Top 10 Gender Identities</h3>
              <ol class="list-decimal list-inside text-sm">
                <li v-for="gender in report.community.genderIdentities.top10" :key="gender">
                  {{ gender }}
                </li>
              </ol>
            </div>
          </figure>
          <p>The variety of gender identities chosen by our members speaks to the inclusive and diverse nature of the Submit community. While "Woman" and "Man" are the most commonly selected, identities like "Non-Binary," "Genderfluid," and "Trans Woman" show that gender expression on Submit is anything but binary. With "Femme," "Masc," and "Agender" also making the list, it’s clear that our members value having the space to express themselves in ways that feel authentic.</p>
          <figure class="grid gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-lg font-bold mb-3">Top 10 Sexual Orientations</h3>
              <ol class="list-decimal list-inside text-sm">
                <li v-for="orientation in report.community.sexualOrientations.top10" :key="orientation">
                  {{ orientation }}
                </li>
              </ol>
            </div>
          </figure>
          <p>Sexual orientation choices on Submit continue to highlight a broad spectrum of attraction and identity. While "Straight" is the most commonly selected, the strong presence of "Pansexual," "Bisexual," and "Demisexual" reinforces the fluidity of attraction among our members. "Queer," "Polysexual," and "Fluctuating/Evolving" also made the list, emphasizing the importance of self-discovery and the flexibility many users embrace.</p>
          <figure class="grid gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-lg font-bold mb-3">Top 10 Romantic Orientations</h3>
              <ol class="list-decimal list-inside text-sm">
                <li v-for="orientation in report.community.romanticOrientations.top10" :key="orientation">
                  {{ orientation }}
                </li>
              </ol>
            </div>
          </figure>
          <p>Romantic orientation data shows a mix of traditional and non-traditional relationship preferences. "Polyromantic" leads the list, with "Heteroromantic" and "Panromantic" following closely behind. The presence of "None" and "Aromantic" highlights that for some, romance isn’t a defining factor in their connections. Whether members prefer monogamous, polyamorous, or entirely non-romantic relationships, the data shows a strong diversity in how people approach love and connection.</p>
          <figure class="grid gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-lg font-bold mb-3">Top 10 Pronouns</h3>
              <ol class="list-decimal list-inside text-sm">
                <li v-for="pronoun in report.community.pronouns.top10" :key="pronoun">
                  {{ pronoun }}
                </li>
              </ol>
            </div>
          </figure>
          <p>Pronoun selections continue to reflect the many ways users express themselves. "He/Him" and "She/Her" are the most commonly chosen, but "They/Them," "She/They," and "He/They" show just how many people embrace fluidity in their pronouns. "None," "Any," and "Ask" indicate that some members prefer a more open or personal approach to how they’re addressed. The presence of "It/Its" and "Fae/Faer" further highlights the importance of giving everyone the ability to define their identity on their own terms.</p>
          <h3 class="text-lg font-bold">Average Active Users</h3>
          <p>Average active users per month gives us a snapshot of overall engagement on Submit. This metric helps us understand how many people are regularly participating in the community, whether through posting, interacting, or just browsing. Tracking this over time allows us to see trends in activity and make sure the platform continues to meet the needs of our members.</p>
          <figure class="grid grid-cols-1 md:grid-cols-3 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.community.activeUsers[10]) }}</h3>
              <span>October</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.community.activeUsers[11]) }}</h3>
              <span>November</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.community.activeUsers[12]) }}</h3>
              <span>December</span>
            </div>
          </figure>
          <h3 class="text-lg font-bold">Inactive Account Reminders</h3>
          <p>Inactive account emails are an important step in maintaining an engaged and authentic community on Submit. These notices go out to members who haven’t been active for a while, giving them a chance to return before their account is marked inactive. Rather than focusing on raw user numbers, our goal is to build a space where the majority of accounts represent real, active members. This helps ensure that Submit remains a vibrant, thriving platform for everyone who uses it.</p>
          <figure class="grid grid-cols-1 md:grid-cols-3 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.community.inactiveAccountReminders[10]) }}</h3>
              <span>October</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.community.inactiveAccountReminders[11]) }}</h3>
              <span>November</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.community.inactiveAccountReminders[12]) }}</h3>
              <span>December</span>
            </div>
          </figure>
          <p>Note: Inactive account deactivations will start in Q1 2025, we'll include this data in future quarterly reports.</p>
          <h3 class="text-lg font-bold">Account Deletions</h3>
          <p>Account deletions are a natural part of any online community, and we're working on making the process easier. Whether users are stepping away for personal reasons or simply moving on, we respect their choice to leave. Tracking deletions helps us understand overall platform trends and ensures that our user base reflects those who actively want to be here. While growth is always a priority, our focus remains on fostering an engaged and authentic community rather than just chasing numbers.</p>
          <figure class="grid grid-cols-1 md:grid-cols-3 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.community.accountDeletions[10]) }}</h3>
              <span>October</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.community.accountDeletions[11]) }}</h3>
              <span>November</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.community.accountDeletions[12]) }}</h3>
              <span>December</span>
            </div>
          </figure>
          <h3 class="text-lg font-bold">Account Type</h3>
          <p>Account type distribution gives us a deeper understanding of the makeup of our community, highlighting the proportion of free users versus those who have chosen to support Submit as backers. This metric is an important indicator of how our user base engages with the platform and the level of commitment that comes with backing Submit. It helps us track the balance between accessible, free content and the support that allows us to continue developing and enhancing the platform.</p>
          <figure class="grid grid-cols-1 sm:grid-cols-2 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.community.accountTypes.free) }}%</h3>
              <span>Free</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.community.accountTypes.backer) }}%</h3>
              <span>Backer</span>
            </div>
          </figure>
          <p>Currently, backers make up 0.3% of Submit’s total user base, which reflects a small but dedicated segment of the community. While the majority of users are accessing the platform for free, the contributions from backers play a crucial role in sustaining Submit's growth and ongoing development. Their support allows us to invest in new features and improvements that ultimately benefit all members.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl font-bold text-gold-700 dark:text-gold-500">Waitlist</h2>
          <figure class="grid sm:grid-cols-2 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.waitlist.total) }}</h3>
              <span>Total Waitlist Requests</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.waitlist.approvals) }}</h3>
              <span>Waitlist Approvals</span>
            </div>
            <div class="col-span-full">
              <span>Average wait time for an invite: <strong>{{ formatNumber(report.waitlist.averageWaitTime) }} hours</strong></span>
            </div>
          </figure>
          <p>Our waitlist helps us manage growth authentically. It allows us to focus on improving our anti-bot and evasion technology while ensuring we grow at a pace that our infrastructure can handle. We expect the waitlist to be phased out by late Q1 of this year, as we continue to strengthen these systems and scale our platform to accommodate more users.</p>
          <figure class="grid gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-lg font-bold mb-3">Most Common Waitlist Removal Reasons</h3>
              <ol class="list-decimal list-inside text-sm">
                <li v-for="reason in report.waitlist.removalReasons" :key="reason">
                  {{ reason }}
                </li>
              </ol>
            </div>
          </figure>
          <p>The most common reasons for waitlist removal highlight our ongoing commitment to maintaining a safe and genuine space for our community. We take steps to prevent spam, bots, and other disruptive behaviors by removing accounts tied to known spam or bot IPs and emails. Automated activity detection also helps us ensure that only real users are joining Submit. Additionally, we enforce policies around email verification, appropriate usernames, and ban evasion to ensure that every member has a positive and respectful experience from the start.</p>
          <h3 class="text-lg font-bold">Throaway Email Services</h3>
          <p>In our efforts to keep Submit a secure and trustworthy space, we do not allow throwaway email providers. These temporary, disposable email addresses can be used to bypass verification steps, making it harder to ensure that all accounts are tied to legitimate users. By preventing these types of email addresses, we help maintain the integrity of our community, ensuring that members are committed to creating a real and lasting presence on the platform. This policy is just one of the ways we work to foster a more authentic and engaged space for everyone.</p>
          <figure class="grid grid-cols-1 md:grid-cols-3 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div class="col-span-full">
              <h3 class="text-lg font-bold">Throaway Email Attempts</h3>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.waitlist.throwawayAttempts[10]) }}</h3>
              <span>October</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.waitlist.throwawayAttempts[11]) }}</h3>
              <span>November</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.waitlist.throwawayAttempts[12]) }}</h3>
              <span>December</span>
            </div>
          </figure>
          <p><strong>Can't all email providers be considered throwaway?</strong> While it's true that any email address can be abandoned or no longer used, throwaway email providers are specifically designed to offer temporary, one-time-use email addresses. These services often allow users to bypass registration requirements, avoid proper account verification, or create multiple accounts without any long-term commitment. The issue with these providers is that they are frequently used by individuals seeking to exploit the system, whether for spam, creating fake profiles, or evading bans. By limiting these specific email providers, we ensure that members are more invested in their accounts, helping to maintain a more genuine and secure community for everyone.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl font-bold text-gold-700 dark:text-gold-500">Content</h2>
          <p>Content posted on Submit throughout the quarter continues to reflect the diverse ways our community engages with the platform. We track the total amount of content shared, which includes everything from status updates and comments to media and group posts. The split between public and private content shows how members are balancing visibility with privacy, allowing us to better understand preferences for sharing in a more controlled space versus engaging with the broader community. Additionally, the most common content type offers insight into how users prefer to communicate, whether through images, text, or other forms of media, giving us valuable feedback on what resonates most with our community.</p>
          <figure class="grid gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.content.total) }}</h3>
              <span>Total Pieces of Content Shared in the Quarter</span>
            </div>
          </figure>
          <figure class="grid grid-cols-1 md:grid-cols-3 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div class="col-span-full">
              <h3 class="text-lg font-bold">Content by Audience</h3>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.content.audiences.everyone) }}%</h3>
              <span>Everyone</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.content.audiences.followers) }}%</h3>
              <span>Followers</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.content.audiences.relationships) }}%</h3>
              <span>Relationships</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.content.audiences.circles) }}%</h3>
              <span>Circles</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.content.audiences.groups) }}%</h3>
              <span>Private Groups</span>
            </div>
          </figure>
          <figure class="grid gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ report.content.commonContentType }}</h3>
              <span>Most Common Content Type</span>
            </div>
          </figure>
          <p>As we close out the content section, it's clear that our members are actively engaging in a variety of ways. With a total of 37,363 pieces of content posted, the breakdown of content audiences gives us valuable insights into how people are choosing to share. The majority of content is being shared with everyone or followers, but there's also a significant portion directed at more intimate groups like relationships, circles, and private groups. This highlights the diverse ways our members connect with each other, whether in public or more private spaces. The most common content type is status updates, reinforcing the importance of quick, real-time communication within the community.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl font-bold text-gold-700 dark:text-gold-500">Reactions</h2>
          <p>Reactions provide a meaningful way for members to engage with content on Submit, offering a quick and expressive way to show how they feel about posts and updates. In this section, we’ll share the total number of reactions in the quarter, along with a breakdown of each type, including likes, loves, laughs, and more.</p>
          <figure class="grid gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(getTotalReactions(report.reactions)) }}</h3>
              <span>Total Reactions in the Quarter</span>
            </div>
          </figure>
          <figure class="grid grid-cols-1 sm:grid-cols-2 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div class="col-span-full">
              <h3 class="text-lg font-bold">Number of Reactions by Type</h3>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.reactions.types.like) }}</h3>
              <span>{{ getEmojiForType("like") }} Like</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.reactions.types.love) }}</h3>
              <span>{{ getEmojiForType("love") }} Love</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.reactions.types.laugh) }}</h3>
              <span>{{ getEmojiForType("laugh") }} Laugh</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.reactions.types.hot) }}</h3>
              <span>{{ getEmojiForType("hot") }} Hot</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.reactions.types.cry) }}</h3>
              <span>{{ getEmojiForType("cry") }} Cry</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.reactions.types.celebrate) }}</h3>
              <span>{{ getEmojiForType("celebrate") }} Celebrate</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.reactions.types.dislike) }}</h3>
              <span>{{ getEmojiForType("dislike") }} Dislike</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.reactions.types.angry) }}</h3>
              <span>{{ getEmojiForType("angry") }} Angry</span>
            </div>
          </figure>
          <p>Note: These numbers may be lower than expected due to the limited discoverability of content in Q4, prior to the introduction of the "Everyone" feed. As Submit continues to evolve and expand, we anticipate these numbers will grow as our platform's visibility and engagement features improve.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl font-bold text-gold-700 dark:text-gold-500">Traffic</h2>
          <p>In this first report, we’re sharing a high-level overview of Submit’s traffic data, including total page views and the average bounce rate for each month. While this offers a basic snapshot of how users are interacting with the platform, we’re gearing up to release ads in the upcoming quarter, which will allow us to gather more detailed traffic insights. As we continue to refine our tracking and analytics, we look forward to sharing deeper traffic metrics in future reports to better illustrate user behavior and platform engagement.</p>
          <figure class="grid grid-cols-1 md:grid-cols-3 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div class="col-span-full">
              <h3 class="text-lg font-bold">Pageviews by Month</h3>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.traffic.pageViews[10]) }}</h3>
              <span>October</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.traffic.pageViews[11]) }}</h3>
              <span>November</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.traffic.pageViews[12]) }}</h3>
              <span>December</span>
            </div>
          </figure>
          <figure class="grid grid-cols-1 md:grid-cols-3 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div class="col-span-full">
              <h3 class="text-lg font-bold">Bounce Rate by Month</h3>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.traffic.bounceRate[10]) }}%</h3>
              <span>October</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.traffic.bounceRate[11]) }}%</h3>
              <span>November</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.traffic.bounceRate[12]) }}%</h3>
              <span>December</span>
            </div>
          </figure>
          <p>With steady growth in page views throughout the last quarter, we’re already seeing increased engagement across the platform. As we move into Q1 of 2025, we expect to see a significant uptick in traffic with the introduction of the Everyone feed and additional engagement-focused updates. These changes will make content more discoverable and encourage deeper interactions, which should translate into higher page views and longer session times. We’ll continue to monitor these trends and look forward to sharing how these upcoming releases shape Submit’s traffic in future reports.</p>
        </section>
        <section class="space-y-6">
          <h2 class="text-2xl font-bold text-gold-700 dark:text-gold-500">Nerdy Stats</h2>
          <p>We know we said to let us know if things ever feel too technical, but this section? Yeah, it’s nerdy on purpose. The Nerdy Stats section is all about the behind-the-scenes numbers that power Submit—things like infrastructure changes, system performance, and development activity. We recognize that not everyone will find these metrics immediately understandable, and we’ll work to provide better explanations in the future. For those who love the details, though, this section offers a glimpse into the ongoing work that keeps Submit running smoothly and evolving.</p>
          <h3 class="text-lg font-bold">Releases</h3>
          <p>We're always working on new features and improvements to Submit. Here are the number of releases we had in each month. You can learn more about our releases on our <router-link class="underline decoration-dotted hover:decoration-solid hover:decoration-gold-700 dark:hover:decoration-gold-500" to="/changelog">changelog</router-link>.</p>
          <figure class="grid grid-cols-1 md:grid-cols-3 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.nerdyStats.releases[10]) }}</h3>
              <span>October</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.nerdyStats.releases[11]) }}</h3>
              <span>November</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.nerdyStats.releases[12]) }}</h3>
              <span>December</span>
            </div>
          </figure>
          <h3 class="text-lg font-bold">Code Changes</h3>
          <p>While there isn't a ton of value in the number of lines of code added or removed, we do track it for fun. We're not sure what to do with it yet, but it's interesting to look at.</p>
          <figure class="grid grid-cols-1 md:grid-cols-3 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-xl"><span class="text-green-500 font-bold">+{{ formatNumber(report.nerdyStats.diff[10].added) }}</span> / <span class="text-red-500">-{{ formatNumber(report.nerdyStats.diff[10].removed) }}</span></h3>
              <span>October</span>
            </div>
            <div>
              <h3 class="text-xl"><span class="text-green-500 font-bold">+{{ formatNumber(report.nerdyStats.diff[11].added) }}</span> / <span class="text-red-500">-{{ formatNumber(report.nerdyStats.diff[11].removed) }}</span></h3>
              <span>November</span>
            </div>
            <div>
              <h3 class="text-xl"><span class="text-green-500 font-bold">+{{ formatNumber(report.nerdyStats.diff[12].added) }}</span> / <span class="text-red-500">-{{ formatNumber(report.nerdyStats.diff[12].removed) }}</span></h3>
              <span>December</span>
            </div>
          </figure>
          <h3 class="text-lg font-bold">Services</h3>
          <p>Submit runs a mixed array of services to keep the platform running smoothly. At a high-level we run a large modular monolith which is then supported by much smaller microservices. As we continue to release more features, we'll be moving more and more functionality into our microservices to keep the platform running smoothly.</p>
          <figure class="grid grid-cols-1 md:grid-cols-3 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.nerdyStats.services[10]) }}</h3>
              <span>October</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.nerdyStats.services[11]) }}</h3>
              <span>November</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.nerdyStats.services[12]) }}</h3>
              <span>December</span>
            </div>
            <div class="col-span-full">
              <span>Services Retired: <strong>{{ report.nerdyStats.services.retired }}</strong></span>
            </div>
          </figure>
          <h3 class="text-lg font-bold">Error Rates</h3>
          <p>While we strive to not have errors occur, they are a natural part of running a platform. Here are the error rates for each month. We built our own APM service to track errors and alert us to any issues. We're still working on getting this data in a more useful format, but we'll get there.</p>
          <figure class="grid grid-cols-1 md:grid-cols-3 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.nerdyStats.errors[10]) }}/hr</h3>
              <span>October</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.nerdyStats.errors[11]) }}/hr</h3>
              <span>November</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.nerdyStats.errors[12]) }}/hr</h3>
              <span>December</span>
            </div>
          </figure>
          <p>Note: October saw a huge uptick during the release of Events due to a bug in the way one of the Events services was reporting errors.</p>
          <h3 class="text-lg font-bold">Feed Downtime</h3>
          <p>The feed service is the most important service on Submit. It's what allows you to see content from the people you follow (and everyone else too). It's also the service that is most susceptible to downtime. Here are the downtime stats for the feed service in each month.</p>
          <figure class="grid grid-cols-1 md:grid-cols-3 gap-6 dark:bg-submit-900 bg-white rounded-lg p-4">
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.nerdyStats.feed.downtime[10]) }} min</h3>
              <span>October</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.nerdyStats.feed.downtime[11]) }} min</h3>
              <span>November</span>
            </div>
            <div>
              <h3 class="text-4xl font-bold">{{ formatNumber(report.nerdyStats.feed.downtime[12]) }} min</h3>
              <span>December</span>
            </div>
          </figure>
          <p>We're pretty sure that's a first, a perfect quarter with no downtime. We're going to have to work to keep it up!</p>
        </section>
      </div>
    </article>
  </main>
</template>
